import $ from 'jquery'
import './jquery-ui'

function initSlider() {
  const min = $('input[name=vykon_od]')
  const max = $('input[name=vykon_do]')
  const slider = $('#slider-range')

  slider.slider({
    range: true,
    step: 1,
    min: 0,
    max: 30,
    values: [parseInt(min.val(), 10) || 0, parseInt(max.val(), 10) || 30],
    slide: function (event, ui) {
      min.val(ui.values[0])
      max.val(ui.values[1])
    },
    stop: () => {
      $('#filter-box').trigger('change')
    },
  })

  min.on('change', function () {
    const value = parseInt($(this).val(), 10) || 0
    slider.slider('values', 0, value)
  })

  max.on('change', function () {
    const value = parseInt($(this).val(), 10) || 0
    slider.slider('values', 1, value)
  })
}

$(document).on('click', '.filter-btn', function () {
  $('#filter-box').toggleClass('hidden')
  $('#filter-modal').toggleClass('z-50')
})

function onSubmit(form) {
  const vykonOd = form.elements.vykon_od
  const vykonDo = form.elements.vykon_do

  if (!vykonOd.value || vykonOd.value == 0) {
    vykonOd.disabled = true
  }

  if (!vykonDo.value || vykonDo.value == 30) {
    vykonDo.disabled = true
  }
}

let initCalled = false
function init() {
  if (initCalled) return
  initCalled = true

  window.addEventListener('popstate', function (event) {
    const queryString = new URLSearchParams(window.location.search).toString()
    const url = `${window.location.pathname}?${queryString}`
    jQueryRefresh(url)
  })
}

window.onFormChange = function (event) {
  init()
  const target = event.currentTarget || event.target
  onSubmit(target)
  const queryString = new URLSearchParams(new FormData(target)).toString()
  const url = `${target.action}?${queryString}`
  const isVisible = !$('#filter-box').hasClass('hidden')
  jQueryRefresh(url, function () {
    if (isVisible) {
      $('#filter-box').removeClass('hidden')
    }
  })
  window.history.pushState(undefined, '', url)
  event.preventDefault()
}

let last = null

function jQueryRefresh(url, callback) {
  last = url
  $.get(url).then(text => {
    if (last !== url) return
    const html = $(text)
    const visible = Array.from(document.querySelectorAll('[data-select-nav-id]'))
      .filter(element => element.classList.contains('select-box--active'))
      .map(element => element.getAttribute('data-select-nav-id'))
    const page = html.filter('#page-container')

    visible.forEach(id => {
      page.find(`[data-select-nav-id="${id}"]`).addClass('select-box--active')
    })
    $('#page-container').replaceWith(page)
    document.title = html.filter('title').text()

    scrollReveal()
    initSlider()
    callback && callback()
  })
}

initSlider()
