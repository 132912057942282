import $ from 'jquery'

$('#show-call-me, #hide-call-me').on('click', function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#call-me').toggleClass('hidden')
})

window.setTimeout(() => {
  $('.snackbar').fadeOut()
}, 5000)
