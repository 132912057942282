import $ from 'jquery'

$('.switch__box').on('click', function () {
  const html = $('html')
  let colorScheme
  if (html.hasClass('dark')) {
    colorScheme = 'light'
    html.removeClass('dark').addClass('light')
  } else if (html.hasClass('light')) {
    colorScheme = 'dark'
    html.removeClass('light').addClass('dark')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    colorScheme = 'light'
    html.removeClass('dark').addClass('light')
  } else {
    colorScheme = 'dark'
    html.removeClass('light').addClass('dark')
  }
  const payload = new FormData()
  payload.append('colorScheme', colorScheme)
  fetch(`/prefers-color-scheme`, {
    method: 'POST',
    body: payload,
  })
})

$('#menu-box').on('click', function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#header').toggleClass('header--open')
})
$('#menu-product')
  .mouseover(function () {
    this.classList.add('menu-product--open')
    $('#nav-product').removeClass('invisible opacity-0')
  })
  .mouseleave(function () {
    this.classList.remove('menu-product--open')
    $('#nav-product').addClass('invisible opacity-0')
  })
$('#menu-lang')
  .mouseover(function () {
    $('#menu-lang-box').removeClass('hidden')
  })
  .mouseleave(function () {
    $('#menu-lang-box').addClass('hidden')
  })
;(function () {
  var el = document.getElementById('container-move')
  if (!el) return
  var rect = el.getBoundingClientRect()
  var mouse = { x: 0, y: 0, moved: false }

  $('#container-move').mousemove(function (e) {
    mouse.moved = true
    mouse.x = e.clientX - rect.left
    mouse.y = e.clientY - rect.top
  })

  // Ticker event will be called on every frame
  TweenLite.ticker.addEventListener('tick', function () {
    if (mouse.moved) {
      parallaxIt('.img-move', -40)
    }
    mouse.moved = false
  })

  function parallaxIt(target, movement) {
    TweenMax.to(target, 0.3, {
      x: ((mouse.x - rect.width / 2) / rect.width) * movement,
      y: ((mouse.y - rect.height / 2) / rect.height) * movement,
    })
  }

  $(window).on('resize scroll', function () {
    rect = el.getBoundingClientRect()
  })
})()

$(document).on('click', '.select-box', function (event) {
  $(this).toggleClass('select-box--active')
})
;(function () {
  //caches a jQuery object containing the header element
  var header = $('.header__wrap')
  $(window).scroll(function () {
    var scroll = $(window).scrollTop()
    if (scroll >= 40) {
      header.addClass('header__wrap--scroll')
    }
  })
  $(window).scroll(function () {
    var scroll = $(window).scrollTop()
    if (scroll <= 10) {
      header.removeClass('header__wrap--scroll')
    }
  })
})()

$('#help-btn').on('click', function () {
  $('.help-box').toggleClass('hidden')
})

$('#year-1-btn').on('click', function () {
  $('.year').addClass('hidden')
  $('#year-1').removeClass('hidden')
})

$('#year-2-btn').on('click', function () {
  $('.year').addClass('hidden')
  $('#year-2').removeClass('hidden')
})

$('#year-3-btn').on('click', function () {
  $('.year').addClass('hidden')
  $('#year-3').removeClass('hidden')
})

$('#help-name-btn').on('click', function () {
  $('.help-name-box').toggleClass('hidden')
})
