import $ from 'jquery'

$('#show-3d, #3dviewer-close').on('click', function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#3dviewer').toggleClass('hidden')
})

$('#show-showroom, #showroom-close').on('click', function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#showroom').toggleClass('hidden')
})
